.ContactForm {
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0px auto;
    background-color:"#d8d8d8";
  }

  Form {
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 600px;
    padding: 55px;
    align-items: center;
    justify-content: center;
  }

.ContactField {
  height: 100px;
  width: 500px;
}

.ContactFieldLarge {
  width: 500px;
}

.ContactField input {
  height: 50px;
  width: 500px;
}

.ContactFieldLarge input {
  height: 100%;
  width: 500px;
}

.ButtonDiv{
  padding-top: 20px;
  orientation: portrait;
}